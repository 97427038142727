.filters {
  width: 260px;
  padding-top: 47px;
  & > div:first-of-type {
    min-height: 161px;
  }
  &__choosen {
    min-height: 108px;
  }
  &__filter {
    a {
      padding-top: 4px;
      padding-bottom: 4px;
      display: block;
    }
  }
  &__selected {
    .btn-link {
      height: 11px;
      width: 11px !important;
      border: 0px;
    }
    .btn-link > img:nth-child(1) {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  &__circles {
    margin-bottom: calc((10 / #{$rem}) * 1rem);
    span {
      margin-bottom: calc((7.5 / #{$rem}) * 1rem);
      display: inline-block;
    }
  }
  &__button {
    font-size: 17px;
    font-weight: bold;
    border: 0px;
  }
  .circle-wrapper {
    display: flex;
    gap: 8px;
    transform: rotate(180deg);
    justify-content: end;

    .point-circle {
      height: calc((16 / #{$rem}) * 1rem);
      width: calc((16 / #{$rem}) * 1rem);
      border-radius: 50%;
      border: 1px solid $black;
      transition: 0.3s;
      background-color: transparent;
      cursor: pointer;
      &.active {
        background-color: $black;
        ~ div,
        a {
          background-color: $black;
        }
      }
    }
  }
  .form-check {
    min-height: initial;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .form-check-label {
    width: 100%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  .form-check-input {
    margin-top: 0px;
    border: 1px solid $black;
    margin-right: 10px;
    height: calc((18 / #{$rem}) * 1rem);
    width: calc((18 / #{$rem}) * 1rem);
  }
  .form-check-input:checked[type="checkbox"] {
    background-color: $black;
    background-image: none;
  }
  .accordion-item {
    padding-top: 7px;
    padding-bottom: 10px;
    border-bottom: 1px solid $black;
  }
  .accordion-button:not(.collapsed) {
    color: $black;
    &::after {
      filter: brightness(0);
    }
  }
  .accordion-button::after {
    height: calc((18 / #{$rem}) * 1rem);
    width: calc((18 / #{$rem}) * 1rem);
    background-size: contain;
  }
}
.products {
  select {
    max-width: 46%;
  }
  .parallax-window {
    height: 161px;
    min-height: initial;
  }
}
@media (min-width: 1950px) {
  .products {
    .row {
      justify-content: center;
      > div:nth-of-type(2) {
        max-width: 1400px;
      }
    }
  }
}
@media (max-width: 1199.9px) {
  .filters {
    padding-top: 0px;
    position: fixed;
    z-index: 2;
    background-color: white;
    height: 100vh;
    transform: translateX(-101%);
    transition: 0.4s;
    &.show {
      transform: translateX(0);
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: white;
      top: -50%;
      left: 0px;
      z-index: -1;
    }
    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 100%;
      background-color: white;
      top: 0%;
      left: -20px;
      z-index: -1;
    }
  }
}
@media (max-width: 767.9px) {
  .filters.show{
    overflow-y: auto;
    top:0;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .products {
    .filters {
      &__button {
        max-width: 45%;
        text-align: start;
      }
    }
    .form-select {
      max-width: 48%;
    }
  }
}
@media (max-width: 575.9px) {
  .products {
    .card {
      &__info {
        font-size: calc((14 / 18) * 1rem);
        line-height: calc((15 / 18) * 1rem);
        padding: calc((6 / 18) * 1rem) calc((8 / 18) * 1rem);
      }
      &__text {
        padding: 5vw;
        padding-bottom: 2vw;
      }
      .fz-14 * {
        font-size: calc((12 / 18) * 1rem);
        line-height: calc((18 / 18) * 1rem);
      }
      li {
        font-weight: 400;
      }
      li::after {
        top: 1.2em;
      }
    }
  }
}
