body {
  width: 100vw;
  overflow-x: hidden;
  padding-right:0!important;
}
main {
  padding-top: calc(#{$header-height} - 1px);
  &.without-banner {
    padding-top: calc(#{$header-height} + 25px);
  }
}
section {
  overflow-x: clip;
  position: relative;
}
select {
  background-position: right 8px center !important;
}
.row {
  > div * {
    --bs-gutter-x: 0.89rem;
  }
}
.btn {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  font-size: 12px;
  text-transform: uppercase;
  &--auto {
    width: auto;
  }
  &--icon {
    width: auto;
  }
  &--small {
    width: 162px;
  }
  &--xs {
    width: 118px;
  }
  &--low {
    height: 27px;
  }
  &-dark:hover {
    color: $primary;
  }
}
.search-input {
  button {
    height: calc((40 / #{$rem}) * 1rem);
    right: 0px;
    min-height: initial;
    z-index: 5 !important;
    img {
      height: calc((21 / #{$rem}) * 1rem);
    }
    &:active {
      border-color: transparent !important;
    }
  }
}
.form-control {
  border-bottom: 1px solid $primary;
  border-radius: 0px;
  max-width: 290px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $gray !important;
  opacity: 1; /* Firefox */
}
.form-control::placeholder {
  color: $gray !important;
  opacity: 1;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $gray !important;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $gray !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.swiper-buttons {
  .swiper-button-next,
  .swiper-button-prev {
    position: relative;
    left: initial;
    right: initial;
    margin-top: 0px;
    height: 40px;
    color: $primary;
    transition: 0.3s;
    &::after {
      font-size: 40px;
    }
  }
  .swiper-button-prev:hover {
    transform: translateX(-2px);
  }
  .swiper-button-next:hover {
    transform: translateX(2px);
  }
}

.question {
  &__img {
    height: 15px;
    width: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: $gray;
    -webkit-mask: url("../img/question.svg") no-repeat center;
    mask: url("../img/question.svg") no-repeat center;
    transition: 0.3s;
    margin-right: 6px;
  }
  &__link {
    &:hover {
      div {
        background-color: $primary;
      }
    }
  }
}
.container {
  &--primary {
    max-width: 1164px;
    .absolute__half {
      width: 43vw;
      &--right {
        left: 58%;
      }
      &--left {
        right: 58%;
      }
    }
  }
}

.absolute__quarter {
  min-width: 40vw;
  &--right {
    left: 70%;
  }
  &--left {
    right: 70%;
  }
}

.logos {
  max-width: 1000px;
  img {
    flex-basis: 25%;
    max-width: 100%;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-of-type {
      justify-content: start;
    }
  }
  .funds {
    img {
      height: calc((68 / #{$rem}) * 1rem);
    }
  }
  .pol,
  .woj,
  .union {
    img {
      height: calc((53 / #{$rem}) * 1rem);
    }
  }
}
.mobile-photo {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.background {
  z-index: -1;
}
.z-n1 {
  z-index: -1;
}
.contact-select{
  select{
    display: block!important;
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}
.banner {
  position: relative;
  picture {
    position: absolute;
    height: 100%;
    width: 100%;
    * {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .container {
    position: relative;
    height: 100%;
  }

  &__main {
    height: 800px;
    max-height: 76vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--lower {
      max-height: 575px;
    }
  }
  &__secondary {
    height: 575px;
    max-height: 76vh;
    .container {
      height: 100%;
    }
  }
  &__content {
    background-color: white;
    z-index: 1;
    position: absolute;
    width: 574px;
    padding-left: 56px;
    padding-right: 45px;
    padding: 29px 53px 37px 50px;
    border-bottom: 1px solid #eeeeee;
    background-color: #1c1c1c;
    color: white;
    &--bottom-left {
      bottom: -70px;
      left: 8px;
    }
    &--bottom-right {
      bottom: -50px;
      right: 8px;
    }
    &--top-left {
      top: -80px;
      left: 8px;
    }
    &--top-right {
      top: -80px;
      right: 8px;
    }
    p {
      max-height: 7.66rem;
      margin-bottom: 0px;
      overflow: hidden;
    }
    &::after {
      content: "";
      top: 0px;
      height: 8px;
      width: 100%;
      background-color: $primary;
      position: absolute;
      left: 0px;
    }
    h1,
    h2,
    h3 {
      margin-bottom: 0.9rem;
      color: $primary;
    }
  }
}

.sparralax-static {
  .parallax-window > div:nth-child(1) {
    position: static;
    height: 100%;
    transform: translate(0);
  }
  Element .parallax-window {
    height: 100%;
  }
  Element .parallax-window > div:nth-child(1) > img:nth-child(1) {
    height: 100%;
    object-fit: cover;
  }
}
.btn-dark{
  background-color:#212529;
}
.text-bg{
  background: rgba(255,255,255,0.5);
}
.blog {
  &__cards {
    > div {
      > div,
      > p {
        overflow: hidden;
      }
      > div:nth-of-type(3) {
        max-height: calc((54 / 16) * 1rem);
        padding-right: 16px;
      }
      p {
        max-height: 5.61rem;
        padding-right: 16px;
      }
    }
    &--stairs {
      & > div:nth-child(3n - 1) {
        padding-top: 50px;
      }
      & > div:nth-child(3n) {
        padding-top: 100px;
      }
    }
  }
  &__text {
    height: 5.72rem;
    line-height: 1.44rem;
    overflow: hidden;
    &--big {
      line-height: 1.66rem;
      max-height: 5.11rem;
      overflow: hidden;
    }
  }
}
.page-nav {
  font-weight: 500;
  letter-spacing: 0.14px;
  li {
    display: flex;
    align-items: center;
    .active {
      color: $gray;
    }
  }
  span {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    img {
      max-width: 4px;
      filter: brightness(0);
    }
  }
}
.search-container{
  a{
    display: block;
    text-decoration: none;
    border-bottom: 1px solid #ccc;
  }
  h3,h5{
    a{
      border:none;
    }
  }
  img{
    aspect-ratio: 1;
    object-fit: contain;
  }
}
.shapes {
  position: absolute;
  z-index: -1;
  $x: 45px;
  transform: translateY(-50%);
  top: 50%;
  left: $x;
  &__top {
    top: 0%;
  }
  &__top--middle {
    top: 25%;
  }
  &__center {
    top: 50%;
  }
  &__bottom--middle {
    top: 100%;
  }
  &__bottom {
    top: 100%;
  }
  &__right {
    left: initial;
    right: $x;
  }
  &__one {
    width: calc((341 / #{$rem}) * 1rem);
  }
  &__two {
    width: calc((341 / #{$rem}) * 1rem);
  }
  .simpleParallax {
    overflow: visible !important;
    img {
      width: calc((341 / 280) * 100px);
    }
  }
}
.bar,
.newsletter {
  h4 {
    font-weight: 400;
  }
  .form-control {
    color: white !important;
    transition: 0.3s;
  }
  .form-control:focus {
    color: $primary !important;
    background-color: transparent !important;
    border-color: $primary;
    outline: 0;
    transition: 0.3s;
    -webkit-box-shadow: 0;
    box-shadow: 0;
  }
  &--primary {
    .d-inline {
      color: #1d1d1d;
    }
    .form-check-input {
      border-color: $black;
    }
    svg {
      fill: $black;
    }
    .form-control {
      color: $black !important;
      border-color: $black !important;
      &:focus {
        color: $black !important;
        border-color: $gray !important;
      }
    }
  }
}
.opacity-link {
  color: transparent;
  transition: 0.4s;
  background-color: rgba(0, 0, 0, 0);
}
a:hover {
  .opacity-link {
    top: 0px;
    left: 0px;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.parralax {
  height: 50vh;
  // &__item{
  //   height: 100%;
  // }
}
.parallax-window,
.parralax {
  height: 575px;
  overflow: clip;
  position: relative;
  width: 100vw;

  .simpleParallax {
    position: absolute;
    display: flex;
    align-items: center;
    height: 575px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &--low {
    height: calc((161 / #{$rem}) * 1rem) !important;
  }
  &--small {
    height: 415px;
    > .container {
      height: 100%;
    }
  }
  > .container {
    height: 100%;
  }
}
.parallax-window {
  position: absolute;
}
// .banner.parallax-window {
//   overflow-y: visible;
// }
.scroll-down {
  transition: 0.3s;
  min-height: auto;
  display: block;
  width: auto;
  overflow: visible;
  border: 0px;
  margin-left: 4px;
  &:hover {
    transform: translateY(3px);
  }
  img {
    height: calc((15.85 / #{$rem}) * 1rem);
    width: calc((31.7 / #{$rem}) * 1rem);
  }
}
$main-height: 100%;
.banner__main {
  .parallax-window,
  .parralax {
    min-height: $main-height;
    .simpleParallax {
      height: $main-height;
    }
  }
}
.banner.parallax-window {
  overflow-y: visible;
}
.sparallax-static {
  height: 100%;
  * {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
.trainings {
  &__vertically {
    background-image: url("../img/gray-shapes.jpg");
    background-size: cover;
    background-position: center;
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    h2 {
      flex-basis: 100%;
      display: flex;
      margin-bottom: 0px;
      justify-content: center;
      align-items: center;
      font-size: 2.5rem;
    }
  }
  &__content {
    padding: 4.22rem 12.7rem 2rem 6.55rem;
    .btn {
      margin-top: 2rem;
    }
  }
}
.files-img {
  display: flex;
  margin-bottom: calc((64 / #{$rem}) * 1rem);
  img {
    max-width: calc((209 / #{$rem}) * 1rem);
  }
}
footer {
  ul {
    li {
      list-style: none;
      margin-bottom: 15px;
      line-height: 1;
      a {
        text-decoration: none;
        font-size: 0.66rem;
        letter-spacing: 0.6px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
  p {
    margin-bottom: 3px;
    a {
      color: black;
      text-decoration: none;
    }
  }
  .logo {
    img {
      height: 101px;
      width: 107px;
    }
  }
  .adress {
    > a {
      img {
        margin-bottom: 1.7rem;
      }
    }
  }
  .social {
    width: 133px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 21px;
    margin-top: 22px;
  }
}
.card {
  border: 1px solid $gray-secondary;
  height: 100%;
  &__text {
    padding: 1rem;
    background-color: $gray-tertiary;
    height: 100%;
  }
  &__header {
    margin-top: 0.55rem;
    margin-bottom: 1.1rem;
    min-height: 3.5rem;
  }
  li {
    margin-bottom: 0.22rem;
    font-weight: 500;
  }
  &__info {
    position: absolute;
    background-color: $black;
    color: white;
    text-transform: uppercase;
    padding: calc((6 / #{$rem}) * 1rem) calc((16 / #{$rem}) * 1rem);
    top: 12px;
    left: 0px;
    font-weight: bold;
    z-index: 1;
  }
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
  &:hover {
    width: 20px;
  }
}

.form-check-label {
  font-size: 12px;
}
.form-check {
  $distance: 19px;
  padding-left: $distance;
  .form-check-input {
    margin-left: -$distance;
  }
}
.form-check-input {
  margin-top: 0.19em;

  // box-sizing: content-box;
  $size: 2px;
  $color: white;
  transition: 0.2s;
  background-color: white;
  // -webkit-box-shadow: inset 0px 0px 0px 0px $color;
  // -moz-box-shadow: inset 0px 0px 0px 0px $color;
  // box-shadow: inset 0px 0px 0px 0px $color;
  -webkit-box-shadow: inset 0px 0px 0px $size $color;
  -moz-box-shadow: inset 0px 0px 0px $size $color;
  box-shadow: inset 0px 0px 0px $size $color;

  &.bg-gray {
    $color: $gray-tertiary;
    -webkit-box-shadow: inset 0px 0px 0px $size $color;
    -moz-box-shadow: inset 0px 0px 0px $size $color;
    box-shadow: inset 0px 0px 0px $size $color;
  }
}
.newsletter {
  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $primary !important;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $primary !important;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $primary !important;
  }
  .form-control {
    border-color: $gray;
    color: $black;
  }
  .form-control::placeholder {
    color: $primary !important;
    opacity: 1;
  }
  &--primary {
    input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $black !important;
      opacity: 1; /* Firefox */
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $black !important;
    }

    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $black !important;
    }
    .form-control::placeholder {
      color: $black !important;
      opacity: 1;
    }
  }
  .btn {
    width: 169px;
  }
}
.job {
  &__offers {
    & > div:last-of-type {
      border-bottom: 1px solid #dcdcdc;
    }
  }
  &__offer {
    height: 96px;
    border-top: 1px solid #dcdcdc;
  }
  &__city,
  &__mode {
    span {
      position: relative;
      font-size: 17px;
      color: black;
      &::after {
        position: absolute;
        content: "";
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("../img/city.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: 20px;
        width: 16px;
      }
    }
  }
  &__mode {
    span {
      &::after {
        background-image: url("../img/home.svg");
      }
    }
    &.hybrid {
      span {
        &::after {
          background-image: url("../img/globe.svg");
        }
      }
    }
  }
}

.persons {
  &__image {
    border-right: 1px solid $primary;
    font-weight: 600;
    .zoom {
      width: 143px;
      height: 143px;
    }
    &--big {
      .zoom {
        width: 219px;
        height: 219px;
      }
    }
  }
  &__text {
    height: 11.44rem;
    overflow: hidden;
    padding-top: 10px;
  }
}
@media (min-width: 576px) {
  .absolute {
    position: absolute;
    height: 100%;
    .zoom {
      height: 100%;
    }
    &__half {
      width: 51vw;

      &--left {
        right: 50%;
      }
      &--right {
        left: 50%;
      }
    }
    &__equal-parent {
      width: 120%;
      &--left {
        right: 100%;
      }
    }
    &.parallax-window {
      min-height: initial;
    }
  }
}
@media (min-width: 992.1px) {
  .trainings {
    &__vertically {
      h2 {
        writing-mode: tb-rl;
        transform: rotate(180deg);
      }
    }
  }
}
@media (max-width: 1439.98px) {
  .parallax-window {
    height: 550px;
    &--small {
      height: 350px;
    }
  }
  .absolute__quarter {
    width: 35vw;
    &--right {
      left: 65%;
    }
    &--left {
      right: 65%;
    }
  }
}
@media (max-width: 1199.98px) {
  .parallax-window {
    height: 500px;
    &--small {
      height: 300px;
      //   .container {
      //     height: 300px;
      //   }
      // }
      // .container {
      //   height: 500px;
      // }
    }
  }
}
@media (max-width: 991.9px) {
  .shapes {
    display: none;
  }
  .true-parallax {
    height: 100% !important;
  }
  .unclickable {
    pointer-events: none;
  }
  .logos {
    > div {
      &:first-of-type {
        justify-content: center;
      }
    }
  }
  main {
    padding-top: $header-height-mobile;
    &.without-banner {
      padding-top: $header-height-mobile;
    }
  }
  .banner {
    height: calc(100vh - #{$header-height-mobile} - #{$mobile-toolbar});
    .container {
      height: 100%;
    }
    &__main {
      max-height: initial;
    }
    .swiper-slide {
      > img {
        position: absolute;
      }
    }
    &__content {
      top: inherit;
      bottom: 0px;
    }
    .simpleParallax {
      min-height: 100%;
    }
  }

  .parallax-window {
    min-height: 400px;
  }
  .blog {
    &__cards {
      &--stairs {
        & > div:nth-child(3n - 1),
        & > div:nth-child(3n) {
          padding-top: 0px;
        }
      }
    }
  }
  .trainings {
    &__vertically {
      position: static;
      height: 252px;
      flex-direction: row;
      padding-bottom: 0px;
      justify-content: space-around;
      background-size: contain;
      h2 {
        writing-mode: initial;
        flex: initial;
      }
    }
  }
  .parallax-window.absolute {
    display: none;
  }
  .job {
    h2 {
      text-align: center;
    }
    h5 {
      text-align: center;
    }
    &__offer {
      width: 80%;
      min-width: 300px;
      margin: auto;
      min-height: 96px;
      height: initial;
      justify-content: center;
    }
    &__city {
      margin-left: 30px;
    }
  }
  .persons {
    &__image {
      margin-bottom: 25px;
      border-right: 0px;
      &--mobile-bigger {
        .zoom {
          width: 195px;
          height: 195px;
        }
      }
    }
    &__text {
      text-align: center;
      height: 194px;
    }
    &__name {
      font-size: 1.3rem;
      line-height: 1;
    }
  }
}
@media (max-width: 767.9px) {
  .absolute__quarter {
    position: static;
    width: 100%;
  }
  .brand {
    padding: 30px;
    img {
      left: 44px;
    }
  }
  .parallax-window {
    min-height: 320px;
  }
  .bar {
    text-align: center;
  }
  .upload {
    &__load {
      input {
        width: 135px;
      }
    }
    &__main {
      width: 328px;
      margin: auto;
    }
  }
  .range-header span{
    display: block;
  }
}
@media (max-width: 575.9px) {
  .parallax-window--low {
    height: 102px !important;
    min-height: initial;
  }
  .catalogs-links {
    .padding-thing--71 {
      padding-bottom: 50% !important;
    }
  }
  .banner {
    &__content {
      width: 100%;
      padding: 19px 17px 16px 10px;
      &--bottom-left {
        bottom: 0px;
        left: 0px;
      }
      &--bottom-right {
        bottom: 0px;
        right: 0px;
      }
      &--top-left {
        top: initial;
        bottom: 0px;
        left: 0px;
      }
      &--top-right {
        top: initial;
        bottom: 0px;
        right: 0px;
      }
      h1,
      h3 {
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
  .trainings {
    &__vertically {
      flex-direction: column;
      h2 {
        text-align: center;
      }
    }
    &__content {
      padding: 2rem 3rem 1rem 1.25rem;
    }
  }
  .btn {
    min-width: 150px;
  }
  .container {
    &--primary {
      .absolute__half {
        width: initial;
      }
    }
  }
  .absolute__half {
    order: -1;
  }
  .persons {
    &__header {
      text-align: center;
    }
  }
  .email {
    width: 300px;
    text-align: center;
  }
}
