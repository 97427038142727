@import "nice-select.scss";
@import "variables.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "mixins.scss";
@import "header.scss";
@import "swiper.scss";
@import "articleSlider.scss";
@import "margins.scss";
@import "fonts.scss";
@import "paddings.scss";
@import "shortcuts.scss";
@import "elements.scss";
@import "slickSliders.scss";
@import "otherPages.scss";
@import "login.scss";
@import "registration.scss";
@import "product.scss";
@import "contact.scss";
@import "news.scss";
@import "pattern.scss";
@import "list.scss";

html {
  scroll-behavior: smooth;
}
.make-g-5 .g-5,
.make-g-5 .gx-5,
.make-g-5 > div * {
  --bs-gutter-x: calc((50 / 16) * 1rem) !important;
}
.calc-result {
  h6 {
    margin-bottom: 1rem;
  }
  &__price {
    padding: 0px 1.5rem;
    display: flex;
    height: 60px;
    align-items: center;
    font-size: 1.3rem;
    width: 300px;
    transition: 3s;
    font-weight: 500;
    border-radius: 8px;
  }
}
.d-inline.inline-wrapper,
.d-inline.text-white{
  & > p{
    display:inline;
  }
}
.text-white{
  a{
    color: white;
    text-decoration:underline;
  }
}
#map {
  height: 100%!important;
  width:100%;
}
.compare-img{
  height:260px;
  object-fit:contain;
  width: 100%;
}
.compare-name{
  text-align:center;
  font-weight:500;
  margin-top:5px;
}
.calc {
  &__number {
    width: 100px;
    text-align: center;
  }
  .nice-select {
    background-color: white;
  }
  &__radio {
    padding-left: 0px;
    &:hover {
      img {
        transform: scale(1);
      }
    }
    img {
      $size: 65px;
      height: $size;
      width: $size;
      transition: 1s;
      transform: scale(0.98);
    }
    label {
      $size: 130px;
      min-height: $size;
      width: $size;
      margin:auto;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      text-align: center;
      font-size: 12px;
      align-items: center;
      cursor: pointer;
      border: 2px solid transparent;
      display: flex;
      transition: 1s;
      justify-content: top;
      &.checked {
        border-color: #e99e03;
      }
    }
    input {
      position: absolute;
      opacity: 0;
    }
  }
}
.range-input::-webkit-slider-runnable-track {
  background: transparent;
}

.range-input::-moz-range-track {
  background: transparent;
}
input[type="range"]::-webkit-slider-thumb {
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  cursor: pointer;
}
.range-input {
  --percentage: 0%;
}
.range-header {
  line-height: 2.7rem;
  input {
    height: 2rem;
    width: 6rem;
    padding: 0 5px;
    line-height: 1;
  }
}
.range-container {
  position: relative;
  z-index: 1;
  .background-range {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 6px;
    border-radius: 3px;
    width: 100%;
    z-index: -1;
    &::-moz-range-track {
      background-color: transparent;
    }
  }
}

.cards {
  .nice-select {
    z-index: 101;
  }
}
.card {
  .check-list {
    height: 4.375rem;
    overflow: hidden;
  }
}
.circle-manual {
  position: relative;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 90%;
    transform: translate(-50%, -50%);
  }
}
.check-opener {
  bottom: 0.14rem;
  cursor: pointer;
  img,
  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}
.outside-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  width: 100%;
  transition: 0.5s ease-out;
  &.opened {
    grid-template-rows: 1fr;
    margin-top: 0.2rem;
  }
  .inside-wrapper {
    overflow: hidden;
  }
}
.additional-law {
  filter: grayscale(50%);
  // color: #9a9a9a;
}
.our-brands-section {
  &__card {
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    .col-md-7 img{
      height: 200px;
      object-fit: contain;
      width: 100%;
    }
    a {
      .arrow {
        height: calc((44 / #{$rem}) * 1rem);
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        & > div {
          // transform: translateX(20px);
          width: calc(100% - 20px);
          overflow: hidden;
          height: 100%;
        }
        span {
          $transition: 0.5s;
          position: relative;
          left: 0px;
          transition: $transition;
          transition-delay: calc(#{$transition} / 2);
          display: inline-block;
          height: 4;
          &::before {
            content: "";
            background-image: url("../img/szczalka.svg");
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            left: calc((0 / #{$rem}) * 1rem);
            background-position: center;
            top: 50%;
            transform: translateY(-50%);
            height: calc((44 / 18) * 1rem);
            width: calc((22.5 / #{$rem}) * 1rem);
          }
          &::after {
            content: "";
            position: absolute;
            left: calc((-62.62 / #{$rem}) * 1rem);
            height: calc((3 / 18) * 1rem);
            width: calc((40 / #{$rem}) * 1rem);
            top: 50%;
            background-color: $black;
            transform: translateY(-50%);
            transition: $transition;
            border-radius: 0px 1px 1px 0px;
          }
        }
        &--no-after {
          span {
            left: 0px;
          }
          span {
            left: 0px;
            &::after,
            &::before {
              display: none;
            }
          }
        }
        &--up {
          bottom: initial;
          top: 0px;
        }
      }
      &:hover {
        .arrow {
          span {
            left: 49px;
          }
          span {
            &::after {
              left: calc((-19 / #{$rem}) * 1rem);
            }
          }
          &--no-after {
            span {
              left: 0px;
            }
          }
        }
      }
    }
    * {
      --bs-gutter-x: 0px;
    }
    a {
      height: 100%;
      display: flex;
      .zoom {
        height: 100%;
      }
    }
  }
  &__card:hover {
    // .our-brands-section__arrow {
    //   background-image: url("../img/long-arrow.svg");
    //   height: calc((44.3 / #{$rem}) * 1rem);
    //   width: calc((55 / #{$rem}) * 1rem);
    // }
    .our-brands-section__text {
      background-color: $primary;
    }
  }
  // &__arrow {
  //   background-image: url("../img/szczalka.svg");
  //   height: calc((44.3 / #{$rem}) * 1rem);
  //   width: calc((22.15 / #{$rem}) * 1rem);
  //   background-size: contain;
  //   background-repeat: no-repeat;
  // }
  &__text {
    padding: calc((14 / #{$rem}) * 1rem) calc((20 / #{$rem}) * 1rem)
      calc((16 / #{$rem}) * 1rem) calc((20 / #{$rem}) * 1rem);
    background-color: $gray-secondary;
    transition: 0.3s;
    div {
      word-break: break-word;
    }
  }
}

.numbers {
  > div {
    $padding: 120px;
    > div:nth-of-type(4n - 1),
    > div:nth-of-type(4n) {
      padding-left: $padding;
    }
    > div:nth-of-type(4n - 2),
    > div:nth-of-type(4n - 3) {
      padding-right: $padding;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: calc((60 / #{$rem}) * 1rem);
  }
  &__number {
    height: calc((158 / #{$rem}) * 1rem);
    width: calc((158 / #{$rem}) * 1rem);
    background-color: $primary;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 66px;
    font-size: calc((86 / #{$rem}) * 1rem);
    position: relative;
    z-index: 1;
    letter-spacing: calc((-4.3 / #{$rem}) * 1rem);
    span {
      position: relative;
      top: -6px;
    }
  }
  &__text {
    flex-shrink: 100;
    position: relative;
    z-index: 1;
  }
  &__image {
    position: absolute;
    left: -40px;
    top: 0px;
    z-index: -1;
    &--right {
      right: -40px;
      left: initial;
    }
  }
}
.logos {
  max-width: 1000px;
  img {
    flex-basis: 25%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .our-brands-section {
    &__card {
      height: calc((202 / #{$rem}) * 1rem);
    }
    &__text {
      width: calc((220 / #{$rem}) * 1rem);
    }
  }
}
@media (min-width: 992px) {
  .range-header {
    min-height: 5.55rem;
  }
}
@media (min-width: 1440px) {
  .our-brands-section {
    &__card {
      width: calc((574 / #{$rem}) * 1rem);
    }
  }
}

@media (max-width: 1439.9px) {
}
@media (max-width: 991.92px) {
  .numbers {
    &__item {
      flex-direction: column;
      margin-bottom: 40px;
    }
    &__number {
      width: 150px;
      height: 150px;
      margin-right: 0px;
      margin-bottom: 20px;
    }
    &__text {
      text-align: center;
    }
  }
}
@media (max-width: 767px) {
  .our-brands-section {
    &__text img {
      height: calc((44.3 / #{$rem}) * 0.5rem);
      width: calc((22.15 / #{$rem}) * 0.5rem);
    }
    &__card .col-md-7 img {
      height:170px;
      object-fit:contain;
    }
  }
}
@media (max-width: 575.9px) {
  .numbers {
    > div {
      > div {
        padding-left: initial !important;
        padding-right: initial !important;
        margin-bottom: 70px;
      }
    }
    &__text {
      max-width: 250px;
    }
  }
  .big-brand {
    padding: 4rem 2rem 4.3rem 2.2rem;
  }
}


.drawer{
  position: fixed;
  top: 50vh;
  transform: translateY(-50%);
  z-index: 100;
  list-style-type: none;
  padding: 0;
  right: 0;
  .simplex, .profix{
    transition: 0.3s;
    width: 50px;
    position: relative;
    right: -5px;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 15px;
      padding-right: 20px;
      padding-block: 5px;
      height: 170px;
      margin-bottom: 5px;
      img{
        width: 25px;

      }
    }
  }:hover{
    right: 0px;
    a {
      padding-right: 15px;
    }
  }




    .simplex{

     a{
        background-color: #bbb;

        img{
          padding-inline: 1px;
        }
      }

    }
    .profix{
      background-color: #005d9f;
    }

}



.brands{
  display: flex;
  width: 80%;
  flex-direction: column;
  padding: 0;
  gap: 20px;
  img{
    height: 15px;
    padding: 0px;
    padding-left: 5px;
  }
  a{
    width: 150px;
    padding: 0px;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

@media (max-width: 991px) {
  .drawer{
    display: none;
  }
}
@media (min-width: 992px) {
  .brands{
    display: none;
  }
}
