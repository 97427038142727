.my-slick-1 {
  .slick-track {
    gap: 1rem;
    display: flex;
    .slick-slide {
      padding: 0px !important;
    }
  }
}
.my-slick-2 {
  .slick-slide {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 31px;
  width: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  transition: 0.3s;
  cursor: pointer;
}
.product-sliders {
  position: relative;
  > div {
    position: absolute !important;
    top: 0px;
    left: 50%;
    opacity: 1;
    z-index: 0;
    transform: translateX(-50%);

    $time: 2s;

    transition: z-index 0.5s step-end, opacity 0.5s linear;

    &:first-of-type {
      position: relative !important;
      transform: translateX(0%);
      left: 0%;
    }
    &.hide {
      z-index: -1;
      opacity: 0;
      transition: z-index 0.5s step-start, opacity 0.5s linear;
    }
  }
}
.slick-arrow-prev,
.slick-arrow-left {
  background-image: url(../img/prev.svg);
  left: -43px;
  &:hover {
    transform: translateY(-50%) translateX(-2px);
  }
}
.slick-arrow-next,
.slick-arrow-right {
  background-image: url(../img/next.svg);
  right: -43px;
  &:hover {
    transform: translateY(-50%) translateX(2px);
  }
}
.slick-arrow-prev.big-click,
.slick-arrow-left.big-click,
.slick-arrow-next.big-click,
.slick-arrow-right.big-click {
  &::before {
    width: 500%;
    height: 300%;
    transform: translate(-41%, -32%);
  }
}
@media (min-width: 1440px) and (max-width: 1530px) {
  .slick-container {
    max-width: 90vw;
  }
}
@media (max-width: 1439.9px) {
  $position: 15px;
  .slick-arrow-prev,
  .slick-arrow-left {
    background-image: url(../img/prev.svg);
    left: $position;
    &:hover {
      transform: translateY(-50%) translateX(-2px);
    }
  }
  .slick-arrow-next,
  .slick-arrow-right {
    background-image: url(../img/next.svg);
    right: $position;
    &:hover {
      transform: translateY(-50%) translateX(2px);
    }
  }
}

@media (max-width: 575.9px) {
  .my-slick-1 {
    .slick-track {
      gap: 0px;
    }
  }
}
