// Contact Boxes

.contact-boxes {
  // height: 960px;
}
.form-box-inside {
  margin-top: 78px;
  margin-bottom: 146px;
}

.form-boxes {
  // height: 960px;
  background-color: #f2f2f2;
}
.contact-form {
  .form-control-input:focus {
    background-color: #e4e4e4 !important;
  }
}
.form-control-input {
  font-size: 18px;
  padding-top: 11px;
  padding-bottom: 10px;
  border-bottom: none;
  margin-bottom: 20px;
  background: #e4e4e4 0% 0% no-repeat padding-box;
  margin-bottom: 16px;
}
.date-contact {
  margin-bottom: 10px;
}
.data-map {
  margin-bottom: 21px;
}
.form-contact-h {
  margin-bottom: 30px;
}
.form-question-box {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-box-inside {
  padding-left: 20px;
  padding-right: 20px;
  width: 660px;
  max-width: 100%;
}

.checkbox-l {
  color: #808080;
}
.checkbox-d {
  margin-bottom: 6px;
}
.form-btn-box {
  margin-top: 17px;
}
.contact-inside {
  width: 456px;
  max-width: 100%;
  margin: 0 126px 0 auto;
  h1 {
    font-weight: 500;
    margin-bottom: 26px;
  }

  p {
    font-size: 18px;
  }
}

.contact-inside-lorem {
  width: 456px;
  max-width: 100%;
}

// section 2

.contact-map-boxes {
  height: 577px;
}

.form-map-boxes {
  height: 577px;
}

.contact-department-boxes {
  // min-height: 637px;
  background-color: #e99e03;
  padding-bottom: 90px;
}
.contact-department-box {
  margin-top: 80px;
}
.circle-contact {
  height: 84px;
  width: 84px;
  border: 3px solid white;
  border-radius: 50%;
  overflow: hidden;
  margin: 40px auto 0;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.contact-department-box-buy {
  margin: 0 20px 0 auto;
}
.contact-department-box-technical {
  margin: 0 20px 0 auto;
}
.box-phones {
  margin-top: 13px;
}
.text-underline-none-black:hover {
  color: black;
  text-decoration: underline;
}
@media (max-width: 767px) {
  .form-map-boxes {
    height: auto;
    text-align: center;
    .data-map {
		h3{
			font-size: 33px;
		}
      p {
        margin-bottom: 10px !important;
		font-weight: 500;
      }
    }
  }
}
@media (max-width: 320px) {
  .contact-department-box-buy {
    margin: 0;
  }
  .contact-department-box-technical {
    margin: 0;
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .contact-department-box-buy {
    margin: 0;
  }
  .contact-department-box-technical {
    margin: 0;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .contact-department-box-buy {
    margin: 0;
  }
  .contact-department-box-technical {
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
}
@media (min-width: 992px) and (max-width: 1200px) {
}
