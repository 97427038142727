.padding__xxxl {
  padding-top: calc((200 / #{$rem}) * 1rem);
  padding-bottom: calc((200 / #{$rem}) * 1rem);
  // 5.55rem = 100px
  &--lower-bottom {
    padding-bottom: calc((100 / #{$rem}) * 1rem);
  }
}
.padding__xxl {
  padding-top: calc((150 / #{$rem}) * 1rem);
  padding-bottom: calc((150 / #{$rem}) * 1rem);
  // 5.55rem = 100px
  &--lower-bottom {
    padding-bottom: calc((75 / #{$rem}) * 1rem);
  }
}
.padding__xl {
  padding-top: calc((130 / #{$rem}) * 1rem);
  padding-bottom: calc((130 / #{$rem}) * 1rem);
  // 5.55rem = 100px
  &--lower-bottom {
    padding-bottom: calc((65 / #{$rem}) * 1rem);
  }
}
.padding__lg {
  padding-top: calc((120 / #{$rem}) * 1rem);
  padding-bottom: calc((120 / #{$rem}) * 1rem);
  // 5.55rem = 100px
  &--lower-bottom {
    padding-bottom: calc((60 / #{$rem}) * 1rem);
  }
}
.padding__md {
  padding-top: calc((100 / #{$rem}) * 1rem);
  padding-bottom: calc((100 / #{$rem}) * 1rem);
  // 5.55rem = 100px
  &--lower-bottom {
    padding-bottom: calc((50 / #{$rem}) * 1rem);
  }
}

.padding__sm {
  padding-top: calc((80 / #{$rem}) * 1rem);
  padding-bottom: calc((80 / #{$rem}) * 1rem);
  &--lower-bottom {
    padding-bottom: calc((40 / #{$rem}) * 1rem);
  }
}
.padding__xs{
  padding-top: calc((60 / #{$rem}) * 1rem);
  padding-bottom: calc((60 / #{$rem}) * 1rem);
  &--lower-bottom {
    padding-bottom: calc((30 / #{$rem}) * 1rem);
  }
  &--greater-bottom {
    padding-bottom: calc((98 / #{$rem}) * 1rem);
  }
}
@media(min-width: 1200px){
  .ps__xl--xxl{
    padding-left: calc((120 / #{$rem}) * 1rem);
  }
}


@media (max-width: 992px) {
  .padding__xxxl {
    padding-top: calc((200 / #{$rem}) * .7rem);
    padding-bottom: calc((200 / #{$rem}) * .7rem);
    &--lower-bottom {
      padding-bottom: calc((100 / #{$rem}) * .7rem);
    }
  }
  .padding__xxl {
    padding-top: calc((150 / #{$rem}) * .7rem);
    padding-bottom: calc((150 / #{$rem}) * .7rem);
    &--lower-bottom {
      padding-bottom: calc((75 / #{$rem}) * .7rem);
    }
  }
  .padding__xl {
    padding-top: calc((130 / #{$rem}) * .7rem);
    padding-bottom: calc((130 / #{$rem}) * .7rem);
    &--lower-bottom {
      padding-bottom: calc((65 / #{$rem}) * .7rem);
    }
  }
  .padding__lg {
    padding-top: calc((120 / #{$rem}) * .7rem);
    padding-bottom: calc((120 / #{$rem}) * .7rem);
    &--lower-bottom {
      padding-bottom: calc((60 / #{$rem}) * .7rem);
    }
  }
  .padding__md {
    padding-top: calc((100 / #{$rem}) * .7rem);
    padding-bottom: calc((100 / #{$rem}) * .7rem);
    &--lower-bottom {
      padding-bottom: calc((50 / #{$rem}) * .7rem);
    }
  }
  .padding__sm {
    padding-top: calc((80 / #{$rem}) * .7rem);
    padding-bottom: calc((80 / #{$rem}) * .7rem);
    &--lower-bottom {
      padding-bottom: calc((40 / #{$rem}) * .7rem);
    }
  }
  .padding__xs{
    padding-top: calc((60 / #{$rem}) * .7rem);
    padding-bottom: calc((60 / #{$rem}) * .7rem);
    &--lower-bottom {
      padding-bottom: calc((30 / #{$rem}) * .7rem);
    }
    &--greater-bottom {
      padding-bottom: calc((98 / #{$rem}) * .7rem);
    }
  }
  .padding__xd {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    &--lower-bottom {
      padding-bottom: 2rem;
    }
  }
  .px-0-lg{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .pt-0-lg {
    padding-top: 0px;
  }
  .pb-1-lg{
    padding-bottom: 1rem;
  }
  .pb-0-lg {
    padding-bottom: 0px;
  }

}
@media (max-width: 768px) {
  .fz-44 {
    font-size: calc((36 / 16) * 1rem);
    line-height: calc((46 / 16) * 1rem);
  }
  .padding__xxxl {
    padding-top: calc((200 / #{$rem}) * .5rem);
    padding-bottom: calc((200 / #{$rem}) * .5rem);
    &--lower-bottom {
      padding-bottom: calc((100 / #{$rem}) * .5rem);
    }
  }
  .padding__xxl {
    padding-top: calc((150 / #{$rem}) * .5rem);
    padding-bottom: calc((150 / #{$rem}) * .5rem);
    &--lower-bottom {
      padding-bottom: calc((75 / #{$rem}) * .5rem);
    }
  }
  .padding__xl {
    padding-top: calc((130 / #{$rem}) * .5rem);
    padding-bottom: calc((130 / #{$rem}) * .5rem);
    &--lower-bottom {
      padding-bottom: calc((65 / #{$rem}) * .5rem);
    }
  }
  .padding__lg {
    padding-top: calc((120 / #{$rem}) * .5rem);
    padding-bottom: calc((120 / #{$rem}) * .5rem);
    &--lower-bottom {
      padding-bottom: calc((60 / #{$rem}) * .5rem);
    }
  }
  .padding__md {
    padding-top: calc((100 / #{$rem}) * .55rem);
    padding-bottom: calc((100 / #{$rem}) * .55rem);
    &--lower-bottom {
      padding-bottom: calc((50 / #{$rem}) * .55rem);
    }
  }
  .padding__sm {
    padding-top: calc((40 / #{$rem}) * .6rem);
    padding-bottom: calc((40 / #{$rem}) * .6rem);
    &--lower-bottom {
      padding-bottom: calc((20 / #{$rem}) * .6rem);
    }
  }
  .padding__xs{
    padding-top: calc((60 / #{$rem}) * .65rem);
    padding-bottom: calc((60 / #{$rem}) * .65rem);
    &--lower-bottom {
      padding-bottom: calc((30 / #{$rem}) * .65rem);
    }
    &--greater-bottom {
      padding-bottom: calc((98 / #{$rem}) * .65rem);
    }
  }
  .px-0-md{
    padding-left: 0px;
    padding-right: 0px;
  }
  .pt-0-md {
    padding-top: 0px;
  }
  body .form-box-inside {
    margin-top: 45px;
  }
  body .contact-department-box {
    margin-top: 40px;
    margin-bottom:0;
  }
  body .contact-department-boxes {
    padding-bottom: 45px;
  }
  body .home-box {
    margin-bottom: 50px;
  }
  body .product__uses {
    margin-bottom: 2.55rem;
  }
}
@media (max-width: 575.9px) {
  .padding__xxxl {
    padding-top: calc((200 / #{$rem}) * .35rem);
    padding-bottom: calc((200 / #{$rem}) * .35rem);
    &--lower-bottom {
      padding-bottom: calc((100 / #{$rem}) * .35rem);
    }
  }
  .padding__xxl {
    padding-top: calc((70 / 16) * 0.4rem);
    padding-bottom: calc((110 / 16) * 0.4rem);
    &--lower-bottom {
      padding-bottom: calc((75 / #{$rem}) * .4rem);
    }
  }
  .padding__xl {
    padding-top: calc((130 / #{$rem}) * .45rem);
    padding-bottom: calc((130 / #{$rem}) * .45rem);
    &--lower-bottom {
      padding-bottom: calc((65 / #{$rem}) * .45rem);
    }
  }
  .padding__lg {
    padding-top: calc((120 / #{$rem}) * .47rem);
    padding-bottom: calc((120 / #{$rem}) * .47rem);
    &--lower-bottom {
      padding-bottom: calc((60 / #{$rem}) * .47rem);
    }
  }


  .pt-0-sm {
    padding-top: 0px;
  }
  .px-0-sm {
    padding-left: 0px;
    padding-right: 0px;
  }
}


// claas Mateusz////


.text-underline-none{
  text-decoration: none;
}

