.accordion-item-box {
	border: none;
	border-bottom: 2px solid #808080;
}
.accordion-button-box {
	padding-left: 0;
	padding-right: 0;
}
.accordion-body-box {
	padding-left: 0;
}

.news-boxes {
	max-width: 928px;
}

@media (max-width: 768px) {
	.news-boxes {
		width: 100%;
	}
}

// modal.1
.btn-close-box {
    font-size: 28px;
}
.form-control-input{
	font-size: 18px;
	padding-top: 11px;
padding-bottom: 10px;
margin-bottom: 16px;
}
.modal-title-box {
}
.modal-content-boxes {
	background-color: #ffffff;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	border-radius: unset;
}

.modal-header-box {
	margin-top: 0;
	color: #ffffff;
	background-color: #1d1d1b;
    border-radius: unset;
}

.modal-body-box {
	width: 100%;
	max-height: 100%;
	padding-bottom: 46px;
	padding-top: 32px;
}
.modal {
	z-index: 100003;
}
.boxes-up{
    height: 8px;
    background-color:#e79d26 ;
}


// modal.2\


.modal-header-thanks-box {
	margin-top: 0;
	color: black;
	background-color: #ffffff;
    
	padding-bottom: 42px;
padding-top: 33px;

}

.modal-body-thanks-box{
	padding-top: 0;
	padding-bottom: 0;
	p{
	margin-bottom: 38px;
	}
	.btn-primary{
		letter-spacing: 1px;
		margin-bottom: 32px;
	}
}