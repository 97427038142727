.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: white;
  transition: 0.8s;
  &__language {
    position: relative;
    a {
      color: #1d1d1b;
    }
    .dropdown-menu {
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 0px;
      top: 1px !important;
      right: -1.9rem !important;
      left: inherit !important;
      min-width: initial !important;
      flex-direction: column;
      border: 0px;
      flex-wrap: wrap;
      &.show {
        display: flex;
      }
      li {
        padding: 10px 5px;
        background-color: white;
      }
      a {
        justify-content: space-between;
        &:hover {
          color: inherit;
        }
      }
    }
    button {
      font-size: 0.94rem !important;
      width: auto !important;
      background: transparent;
      font-weight: 600 !important;
      border: 0px;
      color: #1d1d1b !important;
      padding: 0px !important;
      text-decoration: none !important;
      background-color: transparent !important;
    }

    &:hover {
      &::after {
        background-color: $primary;
      }
      button {
        background-color: transparent !important;
        text-decoration: none;
        &.big-click {
          text-decoration: none;
        }
      }
    }
    &.user-menu{
      .dropdown-menu{
        top: -1px!important;
        padding-top:15px;
        li{
          width:100%;
          text-align:left;
          padding:0 0 15px 10px;
          a{
            height: auto;
            padding-top:0;
          }
        }
      }
    }
  }
  &.hide {
    transform: translateY(-100%);
    .dropdown-menu.show {
      display: none;
    }
  }
  &__container {
    flex-wrap: wrap;
  }
  li {
    list-style: none;
  }
  a {
    text-decoration: none;
    font-weight: 500;
    position: relative;
    text-wrap: nowrap;
    &:hover {
      color: $primary;
    }
    &::after {
      position: absolute;
      content: "";
      height: 3px;
      width: 100%;
      background-color: transparent;
      transition: 0.3s;
      left: 0px;
      bottom: -5px;
      border-radius: 4px;
    }
    &.selected {
      color: $primary;
    }
    &.dropdown-toggle::after{
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      width: auto;
      position: static;
    }
  }
  .user-menu{
    margin-right: 35px;
  }
  &__logo {
    height: 87px;
    display: flex;
    align-items: center;
  }
  &__language {
    position: relative;
    font-size: 0.94rem;
    font-weight: 600;
    &:hover {
      &::after {
        background-color: $primary;
      }
    }
  }
  &__nav {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 101vw;
      background-color: $black;
      height: 100%;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
    &--upper::after {
      display: none;
    }
  }
  &__nav--lower {
    width: 100%;
    height: 50px;
    padding-bottom: 2px;
    > ul {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0px;
      height: 100%;
      padding-left: 0px;
      li {
        display: flex;
        flex-basis: 12%;
        justify-content: center;
        a {
          color: white;
          font-weight: 400;
        }
        ul {
          max-width: calc((176 / #{$rem}) * 1rem);
          word-break: break-all;
        }
        &.position-relative {
          &::after {
            content: "";
            visibility: hidden;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: calc((43 / 18) * 1rem);
            border-left: calc((20 / 18) * 1rem) solid transparent;
            border-right: calc((20 / 18) * 1rem) solid transparent;
            border-bottom: calc((26 / 18) * 1rem) solid #1d1d1b;
            transition: 0.2s;
            opacity: 0;
          }
          &:hover::after {
            visibility: visible;
            opacity: 1;
          }
          &.position-relative:first-of-type:hover::before {
            content: "";
            position: absolute;
            left: 0%;
            height: 44px;
            top: 27px;
            width: calc((175 / 18) * 1rem);
            background-color: transparent;
          }
        }
      }
    }
  }

  &__popover {
    visibility: hidden;
    background-color: $black;
    width: calc((521 / #{$rem}) * 1rem);
    min-height: calc((320 / #{$rem}) * 1rem);
    padding: calc((20 / #{$rem}) * 1rem) calc((40 / #{$rem}) * 1rem);
    position: absolute;
    top: calc((60 / #{$rem}) * 1rem);
    left: 11px;
    opacity: 0;
    transition: 0.2s;
    display: flex;
    &:not(.header__popover--small){
      li:first-child(){
       padding-bottom: 13px;
      }
    }
    ul {
      li.position-relative {
        > div {
          display: block;
          z-index: 0;
        }
      }
      li {
        padding-bottom: calc((20 / #{$rem}) * 1rem);
        justify-content: start;
        &:last-of-type {
          margin-bottom: 0px;
          padding-bottom: 0;
        }
        img{
          object-fit: contain;
        }
      }
    }
    &--small {
      width: initial;
      left: calc((7 / #{$rem}) * 1rem);
      padding: calc((20 / 18) * 1rem);
      padding-right: calc((55 / 18) * 1rem);
      padding-left: calc((37 / 18) * 1rem);
      min-height: initial;
      ul {
        max-width: initial;
        li {
          white-space: nowrap;
          padding-bottom: calc((10 / 16) * 1rem);
        }
      }
    }
  }
  li.position-relative:hover {
    > a {
      color: $primary;
    }
    .header__popover {
      visibility: visible;
      opacity: 1;
    }
  }
  &__category {
    > div {
      position: absolute;
      top: calc((52 / #{$rem}) * 1rem);
      left: calc((233 / #{$rem}) * 1rem);
      width: calc((246 / #{$rem}) * 1rem);
      height: calc((183 / #{$rem}) * 1rem);
      z-index: 1;
      div {
        background-color: $black;
        width: calc((246 / #{$rem}) * 1rem);
      }
    }
  }
  &__search {
    margin-right: 18px;
    input {
      background-color: #f2f2f2;
      border: 1px solid #f2f2f2;
      height: 43px;
      padding: 16px 10px;
      width: 307px !important;
      &:focus {
        background-color: #f2f2f2;
      }
    }
    button {
      position: absolute !important;
      right: 0px;
      z-index: 5 !important;
      &:active {
        border-color: transparent !important;
      }
    }
  }
}
.hamburger {
  display: none;
}
@media (max-width: 1800px) {
}
@media (max-width: 991.9px) {
  .header__nav--lower>ul li.position-relative:hover::after{
    opacity: 0;
  }
  .header {
    position: fixed;
    z-index: 102;
    top: 0px;
    transform: translateX(-100vw);
    &__category {
      .color-hover{
        display:none;
      }
    }
    &__language {
      .dropdown-menu {
        top: -23px !important;
        right: inherit !important;
        left: -15px !important;
        width: 285px !important;
        li {
          width: 25%;
          padding: 10px 0px;
        }
        a {
          color: $primary;
          padding: 0px;
          flex-direction: column-reverse;
          height: 60px;
          padding-top: 5px;
          gap: 0px !important;
        }
      }
      button {
        color: white !important;
        min-width: initial;
        margin-bottom: 15px;
      }
      &::after {
        bottom: 0px;
      }
    }
    &__social {
      margin-left: 0px;
      padding-top: 20px;
      position: static;
      transform: translateX(0px);
      width: 230px;
      img {
        filter: brightness(0%) grayscale(100%) invert(100%);
      }
    }
    a::after {
      width: 31%;
      bottom: 2px;
    }
    &.hide {
      transform: translate(-100vw, -100%);
    }
    &__container {
      height: 100vh;
      > a {
        transform: translateX(100vw);
      }
      &--active {
        // max-height: initial;
        transition: 1s;
        .header__nav--lower a,
        .header__nav--upper a {
          transition: 0.3s;
          width: 100%;
        }
        nav {
          transform: translateX(100vw);
        }
      }
    }
    &__popover {
      width:100%;
      visibility: visible;
      opacity:1;
      position: static;
      padding: 0 0 0 15px;
      min-height: 0;
      display:none;
      ul li{
        margin-bottom: 0;
        padding-bottom:0;
      }
      &:not(.header__popover--small){
        li:first-child(){
          display:none;
        }
      }
    }
    &__search {
      input {
        max-width: 94%;
      }
      button {
        right: 14px;
      }
    }

    &__logo {
      transform: translateX(calc(100vw - 18px));
      padding-left: 18px;
      width: calc(100vw + 11px);
      background-color: white;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 110vw;
        left: 0px;
        top: 0px;
        background-color: white;
        height: 100%;
        z-index: -1;
      }
    }
    &__nav {
      width: 275px;
      margin-right: 223px;
      transition: 1s;

      &--upper,
      &--lower {
        height: calc(50% - 43px);
        a {
          width: 50%;
          transition: 10s;
        }
        li {
          width: 100%;
        }
        &>ul li {
          flex-wrap: wrap;
          &.position-relative::after{
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            border-left:none;
            content: '';
            display: block;
            height: calc(1px * 8);
            margin-top: -9px;
            pointer-events: none;
            position: absolute;
            right: 32px;
            left: initial;
            top: 16px;
            -webkit-transform-origin: 66% 66%;
            -ms-transform-origin: 66% 66%;
            transform-origin: 66% 66%;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-transition: all .15s ease-in-out;
            transition: all .15s ease-in-out;
            width: calc(1px * 8);
            opacity:1;
            visibility: visible;
          }
          &.position-relative:hover::after{
            opacity: 1;
          }
          &.position-relative.active::after{
            -webkit-transform: rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: rotate(225deg);
          }
        }
        &::after {
          display: block;
          width: calc(100% + 25px);
          left: -25px;
          transform: none;
          height: calc(100% + 2px);
        }
      }
      &--upper,
      &--lower ul {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 5px;
        a {
          padding-top: 10px;
          padding-bottom: 10px;
          color: white;
          height: 47px;
          display: inline-block;
        }
      }
      &--lower {
        order: 1;

        padding-top: 20px;
        margin-top: 2px;
        ul {
          align-items: flex-start;
          justify-content: flex-start;
        }
        & > ul{
          //height: 430px;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
      &--upper {
        order: 2;
        justify-content: end;
        padding-bottom: 20px;
        ul {
          position: static;
        }
      }
    }
    // elements nav--upper and nav--lower are swapped
    &__language {
      &::after {
        bottom: 0px;
      }
    }
  }
  .big-click-relative {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 200%;
      height: 200%;
      transform: translate(-25%, -25%);
    }
  }
  .big-click {
    &::before {
      content: "";
      position: absolute;
      width: 200%;
      height: 200%;
      transform: translate(-25%, -25%);
    }
  }
  .hamburger {
    $height: 20px;
    display: flex;
    flex-direction: column;
    height: $height;
    position: fixed;
    top: calc((#{$header-height-mobile} - #{$height}) / 2);
    transform: translateX(100vw);
    left: calc(100vw - 39px);
    justify-content: space-between;
    cursor: pointer;

    span {
      height: 2px;
      position: relative;
      width: 28px;
      transition: 0.3s;
      transform-origin: 1px center 0;
      &::after {
        background-color: $primary;
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
      }
      &:nth-of-type(2) {
        transition: 0.3s;
      }
    }
    &--active {
      span:first-child {
        transform: rotate(45deg);
      }
      span:nth-of-type(2) {
        opacity: 0;
        transition: 0.1s;
      }
      span:last-of-type {
        transform: rotate(-45deg);
      }
    }
  }
  .header__search {
    order: 4;
    input {
      border: 0px;
    }
  }
  @media (max-height: 675px) {
    .header__nav--lower ul a,
    .header__nav--upper a {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 47px;
      padding-top: 3px;
      padding-bottom: 3px;
      height: auto;
      &:empty{
        height:0;
      }
    }
    .header__nav--upper {
      padding-bottom: 12px;
    }
  }
}
@media (max-width: 510px) {
  .header__nav {
    margin-right: 0px;
  }
}
