@media(max-width: 1199.99px){
  .mx-auto-xl{
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media(max-width: 991.99px){
  .mx-auto-lg{
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .me-3-lg{
    margin-right: 1rem !important;
  }
  .me-0-lg{
    margin-right: 0rem !important;
  }
}
@media(max-width: 575.99px){
  .mb-3-sm{
    margin-bottom: 1rem !important;
  }
}