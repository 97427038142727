.product__image {
  max-width: 574px;
  padding-top: calc((20 / #{$rem}) * 1rem);
}
.main-product {
  .check-list {
    li {
      font-weight: 700;
      span {
        font-weight: 400;
        margin-right: 8px;
        text-wrap: nowrap;
        margin-bottom: 5px;
        display: inline-block;
      }
    }
  }
}
.points {
  margin-top: 2.2rem;
}
.variant-select {
  border: 1px solid $black;
  padding: .3rem 2.5rem .3rem 1.35rem;
  .list{
    width: auto;
  }
  &::after {
    right: 1rem;
  }
}
.point {
  width: 22px;
  height: 22px;
  border: 1px solid $black;
  border-radius: 50%;
  margin-right: 11px;
  &.active {
    background-color: $black;
  }
}
.product {
  padding-top: calc((120 / #{$rem}) * 1rem);
  padding-bottom: 50px;
  transition: 0s;
  h2 {
    display: inline;
    border-bottom: 2px solid $gray;
    padding-bottom: 6px;
    font-size: calc((44 / #{$rem}) * 1rem);
    line-height: calc((66 / #{$rem}) * 1rem);
    letter-spacing: calc((0.66 / #{$rem}) * -1rem);
  }
  &__nav {
    &-main {
      position: absolute;
      top: 0px;
      align-items: center;
      box-sizing: content-box;
      transition: padding 0.4s;
      background-color: #f2f2f2;
      max-width: 1376px;
      min-height: calc((70 / #{$rem}) * 1rem);
      width: 100%;
      padding-top: calc((50 / #{$rem}) * 1rem);
      z-index: 10;
      &.position-fixed {
        padding-top: 0px;
        &::after {
          position: absolute;
          content: "";
          width: 200vw;
          left: -50vw;
          bottom: 0px;
          height: 200%;
          background-color: inherit;
          z-index: -1;
        }
      }
    }

    > div {
      display: flex;

      a,
      button {
        padding: calc((10 / #{$rem}) * 1rem) calc((18 / #{$rem}) * 1rem);
        font-weight: 500;
        min-width: initial !important;
        width: auto !important;
        transition: 0.3s;
        &:hover {
          color: $primary;
        }
      }
      a.active,
      button.active {
        background-color: $primary;
        color: $black;
        font-weight: 700;
        &:hover {
          color: initial;
        }
      }
      // &:hover {
      //   a {
      //     @extend a.active;
      //   }
      // }
    }
  }
  &__column {
    max-width: 928px;
  }
  &__item {
    > div:first-of-type {
      margin-bottom: 5rem;
    }
  }
  &__uses {
    margin-bottom: 5.55rem;
    > div,
    > a {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    img {
      width: 123.3px;
      height: 124px;
      margin-bottom: 19px;
    }
    &--big {
      margin-bottom: 0px;
      .text-center {
        max-width: calc((200 / #{$rem}) * 1rem);
      }
      img {
        width: calc((178 / #{$rem}) * 1rem);
        height: calc((178 / #{$rem}) * 1rem);
      }
    }
  }
  &__attachment {
    img {
      position: relative;
      bottom: 4px;
    }
  }
  select {
    position: relative;
    top: -0.8rem;
    left: 32px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='orange' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    padding-right: 2px;
  }
}
.product-sliders > div {
  padding-bottom: 0px;
}
.spec {
  &__header {
    background-color: $black;
    padding: 0.83rem 1rem;
    color: white;
    font-size: 0.88rem;
    font-weight: 700;
    line-height: 1rem;
    letter-spacing: 0.013rem;
    text-transform: uppercase;
  }
  &__row {
    display: flex;
    padding: 0.83rem 1rem;
    background-color: white;
    line-height: 1.22rem;
    letter-spacing: 0.005rem;
    gap:20px;
    > div:first-of-type {
      width: 120px;
    }
    > div:last-of-type {
      min-width: calc(338px - 1rem);
    }
  }
  &__group {
    margin-bottom: 4.37rem;
    > div {
      margin-bottom: 1.11rem;
    }
  }
  &__color {
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    margin-right: 0.8rem;
    position: relative;
    border: 1px solid black;
    &.extra{
      &::after {
        content:'';
        width:1.2rem;
        height:1px;
        position: absolute;
        background-color: black;
        transform: rotate(45deg);
        top: 8px;
      }
    }
  }
}
.app {
  &__header {
    padding: 1rem;
    padding-left: calc(4.5rem + 1rem);
    position: relative;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &::after {
      position: absolute;
      content: "";
      left: 0px;
      top: 0px;
      height: 4.5rem;
      width: 4.5rem;
      background-color: $primary;
    }
  }
  &__item {
    text-align: center;
    height: 4rem;
    padding-left: 0.66rem;
    padding-right: 0.66rem;
    background-color: white;
  }
}
.calculator {
  position: relative;
  h2 {
    color: $primary;
    font-weight: 500;
    line-height: 1;
  }
  &__header {
    background-color: $black;
    width: 7.88rem;
    height: 27.77rem;
    padding: 44px 40px 38px 36px;
    position: sticky;
    top: $header-height;
    margin-left: auto;
  }
  &__bg {
    position: absolute;
    height: 100%;
    width: 1920px;
    left: 100%;
    top: 0px;
    img {
      object-position: left;
    }
  }
  .sparallax-static {
    overflow: hidden;
  }
  .sparallax-scale-left {
    transition: 0.2s;
  }
}
.accesories {
  > div {
    margin-bottom: calc((60 / #{$rem}) * 1rem);
  }
  > div:last-of-type {
    margin-bottom: 0px;
  }
  &__design {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 7.11rem;
      background-color: $primary;
    }
    & > div:first-of-type {
      padding: 1rem;
      padding-left: calc(7.11rem + 1rem);
      position: relative;
      height: calc((54 / #{$rem}) * 1rem);
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: calc((20 / #{$rem}) * 1rem);
    }
    & > div:last-of-type {
      padding-right: 1rem;
      line-height: calc((54 / #{$rem}) * 1rem);
      padding-left: calc(7.11rem + 1rem);
      position: relative;
      // min-height: calc((54 / #{$rem}) * 1rem);
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-color: white;
    }
  }
  &__accesories {
    a {
      display: inline-block;
      padding: calc((6 / #{$rem}) * 1rem);
      position: relative;
      overflow: hidden;
      &::after {
        content: "";
        background-color: $primary;
        position: absolute;
        bottom: 0.7rem;
        left: 0px;
        height: 1px;
        width: 100%;
        transition: 0.4s;
        transform: translateX(-101%);
      }
      &:hover::after {
        transform: translateX(0%);
      }
      &:first-of-type {
        // padding-left: 0px;
        margin-left: -calc((6 / #{$rem}) * 1rem);
      }
      &:last-of-type {
        margin-right: -calc((6 / #{$rem}) * 1rem);
      }
    }
  }
}
@media (max-width: 1439px) {
  .product {
    &__nav {
      max-width: 1164px;
    }
  }
}
@media (max-width: 1199.9px) {
  .product {
    &__uses {
      flex-wrap: wrap;
      > div,
      > a {
        margin-bottom: 2rem;
      }
    }
    &__nav {
      width: 100%;
    }
  }
  .spec {
    &__row {
      > div:last-of-type {
        min-width: calc(338px - 1rem - 30px);
      }
    }
  }
}
@media (max-width: 991.9px) {
  .product {
    padding-top: 0px;
    &__nav {
      flex-wrap: wrap;
      &-main {
        position: static;
        flex-wrap: nowrap;
        overflow-x: scroll;
        justify-content: start!important;
        padding-bottom: 15px;
      }
    }
  }
  .spec {
    &__row {
      > div:first-of-type {
        padding-right: 15px;
      }
      > div:last-of-type {
        justify-content: flex-end;
        display: flex;
        min-width: initial;
        flex-wrap: wrap;
      }
    }
    &__colors {
      gap: 10px 0px;
      div:last-of-type {
        margin-right: 0px;
      }
    }
  }
}
@media (max-width: 767.9px) {
  .product {
    &__item {
      > div:first-of-type {
        width: 100%;
        text-align: center;
        margin-bottom: 4rem;
      }
      h2 {
        text-align: center;
      }
      h4 {
        text-align: center;
      }
    }
    &__uses {
      > div,
      > a {
        width: 100%;
      }
    }
  }
}
@media (max-width: 575.9px) {
  .page-nav {
    line-height: 7px;
  }
  .main-product {
    .question {
      position: relative;
      height: 0px;
      > div {
        position: absolute;
        top: -36px;
      }
    }
    .h2 {
      margin-bottom: 1.1rem;
    }
    ul {
      margin-bottom: 0px;
    }
    .product__image {
      max-height: 45vh;
      padding-top: 0px;
      * {
        height: 100%;
        object-fit: contain;
      }
    }
    .points {
      margin-top: 2.2rem;
    }
  }
  .product {
    &__item {
      &--padding {
        padding-bottom: 416px;
      }
    }
  }
  .calculator__header.fixed-vertical {
    position: fixed;
    top: initial;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 4.88rem;
    padding: 0px 30px;
    flex-direction: row !important;
    transition: 0.3s;
    bottom: -73px;
    h2 {
      transform: rotate(0deg);
      writing-mode: horizontal-tb;
      margin: auto !important;
      padding-bottom: 0px;
    }
    a {
      transform: rotate(-90deg);
      position: absolute;
      right: 20px;
    }
  }
  .vertically {
    transform: rotate(0deg);
    writing-mode: horizontal-tb;
  }
  .calculator__header {
    width: initial;
    height: initial;
  }
  .calculator__bg {
    width: 1920px;
    left: 100%;
    top: 0;
    width: 100vw;
    left: 0%;
    top: -100%;
    display: none;
  }

  .calculator__header {
    flex-direction: row !important;
  }

  .vertically {
    margin: 0px !important;
  }
}
