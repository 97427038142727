// home start/////

.home-box {
  margin-bottom: 90px;
}
.pattern{
  .form-check-input{
    margin-top: .1rem;
    background-image: none !important;
  }
  input,
  .nice-select{
font-size: .78rem !important;
color: grey;
border-width: 1px !important;
  }
}
.breadcrumb-item-home {
  text-decoration: none;
}
.nice-select-search-box {
  display: none;
}

.autocomplete {
  .nice-select-search-box {
    display: block;
  }
  .nice-select .list::-webkit-scrollbar {
    width: 0;
  }

  .nice-select .list {
    margin-top: 5px;
    top: 100%;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    max-height: 210px;
    overflow-y: scroll;
    padding: 52px 0 0;
  }

  .nice-select.has-multiple {
    white-space: inherit;
    height: auto;
    padding: 7px 12px;
    min-height: 53px;
    line-height: 22px;
  }

  .nice-select.has-multiple span.current {
    border: 1px solid #ccc;
    background: #eee;
    padding: 0 10px;
    border-radius: 3px;
    display: inline-block;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 3px;
    margin-right: 3px;
  }

  .nice-select.has-multiple .multiple-options {
    display: block;
    line-height: 37px;
    margin-left: 30px;
    padding: 0;
  }

  .nice-select .nice-select-search-box {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    margin-top: 5px;
    top: 100%;
    left: 0;
    z-index: 8;
    padding: 5px;
    background: #fff;
    opacity: 0;
    pointer-events: none;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 0 1px rgba(68, 88, 112, 0.11);
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(0.75) translateY(-21px);
    -ms-transform: scale(0.75) translateY(-21px);
    transform: scale(0.75) translateY(-21px);
    -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
      opacity 0.15s ease-out;
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  }

  .nice-select .nice-select-search {
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: none;
    color: #333;
    display: inline-block;
    vertical-align: middle;
    padding: 7px 12px;
    margin: 0 10px 0 0;
    width: 100% !important;
    min-height: 36px;
    line-height: 22px;
    height: auto;
    outline: 0 !important;
  }

  .nice-select.open .nice-select-search-box {
    opacity: 1;
    z-index: 10;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
  }

  .remove:hover {
    color: red;
  }
}
// Pattern start///////

.pattern-box {
  width: 928px;
  max-width: 100%;
  margin-bottom: 26px;
}

// checkbox search box////
.checkbox-boxes {
  width: 410px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.checkbox-search-box {
  margin-top: 51px;
  .btn {
    position: absolute;
    width: auto;
    right: calc((30 / 18) * 1rem);
  }
  .form-check-label {
    color: black;
  }
}

.checkbox-color {
  display: inline-flex;
  align-items: start;
  font-size: 12px;
}
.checkbox-d-box {
  width: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.kolo {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// section car///
.car-box-yellow {
  padding-top: 6.55rem;
}
.zoom-box {
  width: 100%;
  height: 100%;

  object-fit: cover;

  object-position: center;
}

.circle-color {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.honey-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.underline {
  margin-top: 5px;
  height: 1px;
  width: 108px;
  background-color: black;
}

.btn-box {
  margin-top: 75.61px;
  margin-right: 12px;
}
.btn-pattern {
  width: 338px;
  max-width: 100%;
  margin-bottom: 19px;
}
.downloand-box {
  margin-top: 2.16rem;
  margin-bottom: 11.55rem;
}

// Allert-box start
.alert-info-boxes {
  width: 100%;
  height: 100%;

  object-fit: cover;

  object-position: center;

  align-items: center;
  display: flex;
}

.alert-box {
  background-color: #e99e03;
  padding-top: 23px;
  padding-bottom: 23px;
}
.alert-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.alert-boxes {
  width: 116px;
  max-width: 100%;
  padding-top: 23px;
  padding-bottom: 23px;
}
.alert-text-boxes {
  width: 810px;
  max-width: 100%;
}

///////////////////////// Circle start///////////////

.form-select-shadow {
  width: 220px;
  border: 2px solid #1d1d1b;
  padding: 7px 7px 7px 14px;
}
.form-select {
  font-size: 18px;
  background-size: 16px 16px;
}

.input-border {
  border: none;
}
.search_wrap {
  width: 456px;
  max-width: 100%;

  border: 2px solid black;
  margin-right: 20px;
}

.search_wrap .search_box {
  position: relative;
  width: 456px;
  max-width: 100%;
  height: 43px;
  max-height: 100%;
}
.search_wrap .search_box .input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 14px;

  font-size: 18px;
}

.search_wrap .search_box .btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;

  z-index: 1;
  cursor: pointer;
}

.search_wrap .search_box .btn.btn_common .fas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 20px;
}

.search_wrap_one {
  width: 220px;
  max-width: 100%;

  border: 2px solid black;
  margin-right: 20px;
}

.search_wrap_one .search_box_one {
  position: relative;
  width: 220px;
  max-width: 100%;
  height: 43px;
  max-height: 100%;
}
.search_wrap_one .search_box_one .input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 14px;

  font-size: 18px;
}

.search_wrap_one .search_box_one .btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;

  z-index: 1;
  cursor: pointer;
}

.search_wrap_one .search_box_one .btn.btn_common .fas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 20px;
}
.search-item{
  border-bottom:1px solid #ccc;
  a{
    border:none;
  }
}
.form-check-label{
  & > p{
    float: left;
    margin-right:5px;
    width: calc(100% - 20px);
  }
  .check-opener {
    position: relative;
    top: -2px;
  }
}
// circle
.calc .nice-select .list{
  width:auto;
  min-width: 100%;
}
.circle-boxes {
  margin-top: 63px;
}
.row-circle {
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}

.circle {
  position: relative;
  height: calc((152 / #{$rem}) * 1rem);
  width: calc((152 / #{$rem}) * 1rem);
  border-radius: 50%;
  text-align: center;
  border: calc((3 / #{$rem}) * 1rem) solid $black;
  $color: white;
  $size: calc((9 / #{$rem}) * 1rem);
  -webkit-box-shadow: inset 0px 0px 0px $size $color;
  -moz-box-shadow: inset 0px 0px 0px $size $color;
  box-shadow: inset 0px 0px 0px $size $color;
  // background-color: red;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: .15s;
  &:hover {
    $size: calc((8 / #{$rem}) * 1rem);
    -webkit-box-shadow: inset 0px 0px 0px $size $color;
    -moz-box-shadow: inset 0px 0px 0px $size $color;
    box-shadow: inset 0px 0px 0px $size $color;
  }
  &--biggest {
    height: calc((163 / #{$rem}) * 1rem);
    width: calc((163 / #{$rem}) * 1rem);
  }
}

.circle-first {
  border-radius: 50%;
  height: calc((127 / #{$rem}) * 1rem);
  width: calc((127 / #{$rem}) * 1rem);
  background-color: #fcaa00;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
}

.row-circle-end {
  margin-bottom: 217px;
}
.h1 .h2, .h2, h1 .h2, h2 {
  line-height: calc((58 / 16) * 1rem);
}
.select-search {
  position: relative;
  font-size: 12px !important;
  height: 40px;
  font-weight: 500;
  color: black;
  border: 1px solid $black !important;
  &::after {
    border-color: white;
    margin-top: -6px;
    right: calc((12 / #{$rem}) * 1rem);
  }
  &::before {
    content: "";
    position: absolute;
    right: 0px;
    height: 100%;
    width: 30px;
    top: 0px;
    background-color: $black;
    width: calc((30 / #{$rem}) * 1rem);
  }
}
///////////////////////// Circle end///////////////

// SEO start/////
.first-text-seo {
  margin-bottom: 60px;

  h3 {
    margin-bottom: 16px;
  }
}
@media (min-width: 576px) {
  .search-button {
    position: relative;
  }
}
@media (max-width: 767px) {
  .title-color {
    max-width: 350px;
    margin: auto !important;
  }
  .downloand-box {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .search-button {
    .btn {
      min-width: 0px !important;
    }
  }
  .container.numbers{
    & ~ img{
      display:none;
    }
  }
  .fz-56,
  .h1 .h2, .h2, h1 .h2, h2 {
    font-size: calc((36 / 16) * 1rem);
    line-height: calc((52 / 16) * 1rem);
  }
  .blog__card {
    margin-bottom: 35px;
  } 
}
// media
@media (max-width: 320px) {
  .btn-box {
    margin-right: 0;
    // }
    // .checkbox-color {
    // 	font-size: 10px;
    // }
    // .checkbox-i {
    // 	top: 0;
    // }
    // .kolo {
    // 	padding-top: 20px;
    // 	width: 456px;
    // 	padding-left: 0;
    // 	padding-right: 0;
    // }
  }
}
@media (max-width: 576px) {
  .checkbox-search-box .btn {
    right: 35px;
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .btn-box {
    margin-right: 0;
  }

  .alert-text-boxes {
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }

  // .checkbox-color {
  // 	font-size: 10px;
  // }
  // .checkbox-i {
  // 	top: 0;
  // }
  // .kolo {
  // 	padding-top: 20px;
  // 	width: 456px;
  // 	padding-left: 0;
  // 	padding-right: 0;
  // }
}
@media (min-width: 576px) and (max-width: 768px) {
  .btn-box {
    margin-right: 0;
  }

  .alert-text-boxes {
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
  // .checkbox-color {
  // 	font-size: 10px;
  // }
  // .checkbox-i {
  // 	top: 0;
  // }
  // .kolo {
  // 	padding-top: 20px;
  // 	width: 456px;
  // 	padding-left: 0;
  // 	padding-right: 0;
  // }
}
@media (min-width: 768px) and (max-width: 992px) {
  // .checkbox-color {
  // 	font-size: 10px;
  // }
  // .checkbox-i {
  // 	top: 0;
  // }
}
@media (min-width: 992px) and (max-width: 1200px) {
}

@media (max-width: 894px) {
  // .kolo {
  // 	padding-top: 20px;
  // 	width: 456px;
  // 	padding-left: 0;
  // 	padding-right: 0;
  // }
}

@media (max-width: 672px) {
  .checkbox-boxes {
    padding-left: 0;
    padding-top: 20px;
  }
}
