@import "variables.scss";
html {
  font-size: 16px;
  color: #1d1d1b;
}

body {
  font-family: "neue-haas-unica", sans-serif;
}
h1 {
  font-size: 2rem;
  font-weight: 400;

  .h2 {
    @extend h2;
  }
  .h3 {
    @extend h3;
  }
  .h4 {
    @extend h4;
  }
  &.big{
    font-size: 5rem;
  }
}
.h1Header {
  font-size: 2rem !important;
  font-weight: 400 !important;

  &.big{
    font-size: 5rem !important;
  }
}
h2 {
  font-size: calc((56 / #{$rem}) * 1rem);
  line-height: calc((84 / #{$rem}) * 1rem);
  letter-spacing: calc((-1.4 / #{$rem}) * 1rem);
  font-weight: 500;
  margin-bottom: calc((28 / #{$rem}) * 1rem);
}
.h2Header {
  font-size: calc((56 / #{$rem}) * 1rem) !important;
  line-height: calc((84 / #{$rem}) * 1rem) !important;
  letter-spacing: calc((-1.4 / #{$rem}) * 1rem) !important;
  font-weight: 500 !important;
  margin-bottom: calc((28 / #{$rem}) * 1rem) !important;
}
h3 {
  font-size: calc((36 / #{$rem}) * 1rem);
  font-weight: 400;
  margin-bottom: 2rem;
  line-height: calc((39.6 / #{$rem}) * 1rem);
  .h5 {
    @extend h5;
  }
}
.h3Header {
  font-size: calc((36 / #{$rem}) * 1rem) !important;
  font-weight: 400 !important;
  margin-bottom: 2rem !important;
  line-height: calc((39.6 / #{$rem}) * 1rem) !important;

}
h4 {
  font-size: calc((28 / #{$rem}) * 1rem);
  margin-bottom: calc((16 / #{$rem}) * 1rem);
  line-height: calc((44 / #{$rem}) * 1rem);
}
.h4Header {
  font-size: calc((28 / #{$rem}) * 1rem) !important;
  margin-bottom: calc((16 / #{$rem}) * 1rem) !important;
  line-height: calc((44 / #{$rem}) * 1rem) !important;
}
h5 {
  font-size: 1.33rem;
  margin-bottom: 1.5rem;
  line-height: 1.3;
}
.h5Header {
  font-size: 1.33rem !important;
  margin-bottom: 1.5rem !important;
  line-height: 1.3 !important;
}
.h6Header {
  font-size: 1rem !important;
  margin-bottom: .5rem !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}
a {
  transition: 0.3s;
  &:hover {
    color: $primary;
    img {
      fill: $primary;
    }
  }
  img{
    transition: .3s;
  }
  &.img-gray:hover {
    img {
  
      filter: invert(76%) sepia(39%) saturate(6352%) hue-rotate(7deg)
        brightness(100%) contrast(98%);
    }
  }
}
// .fz-28{

// }
.fz-56 {
  font-size: calc((56 / #{$rem}) * 1rem);
  line-height: calc((84 / #{$rem}) * 1rem);
  * {
    font-size: calc((56 / #{$rem}) * 1rem);
    line-height: calc((84 / #{$rem}) * 1rem);
  }
}
.fz-44 {
  font-size: calc((44 / #{$rem}) * 1rem);
  line-height: calc((49.28 / #{$rem}) * 1rem);
  letter-spacing: calc((-0.66px / #{$rem}) * 1rem);
  * {
    font-size: calc((44 / #{$rem}) * 1rem);
    line-height: calc((49.29 / #{$rem}) * 1rem);
    letter-spacing: calc((-0.66px / #{$rem}) * 1rem);
  }
}

.fz-36 {
  font-size: 2rem;
  * {
    font-size: 2rem;
  }
}
.fz-28 {
  font-size: calc((28 / #{$rem}) * 1rem);
  line-height: calc((28 / #{$rem}) * 1rem);
  * {
    font-size: calc((28 / #{$rem}) * 1rem);
    line-height: calc((28 / #{$rem}) * 1rem);
  }
}

.fz-26 {
  font-size: 1.55rem;
  * {
    font-size: 1.55rem;
  }
}
.fz-24 {
  font-size: 1.33rem;
  * {
    font-size: 1.33rem;
  }
}
.fz-22 {
  font-size: calc((22 / #{$rem}) * 1rem);
  line-height: calc((26 / #{$rem}) * 1rem);
  * {
    font-size: calc((22 / #{$rem}) * 1rem);
    line-height: calc((26 / #{$rem}) * 1rem);
  }
}
.fz-21 {
  font-size: 1.22rem;
  * {
    font-size: 1.22rem;
  }
}


.fz-16 {
  font-size: calc((16 / #{$rem}) * 1rem);
  line-height: calc((24 / #{$rem}) * 1rem);
  * {
    font-size: calc((16 / #{$rem}) * 1rem);
    line-height: calc((24 / #{$rem}) * 1rem);
  }
}
.fz-14 {
  font-size: calc((14 / #{$rem}) * 1rem) !important;
  line-height: calc((21 / #{$rem}) * 1rem) !important;
  * {
    font-size: calc((14 / #{$rem}) * 1rem) !important;
    line-height: calc((21 / #{$rem}) * 1rem) !important;
  }
}
.fz-12 {
  font-size: calc((12 / #{$rem}) * 1rem);
  line-height: calc((18 / #{$rem}) * 1rem);
  * {
    font-size: calc((12 / #{$rem}) * 1rem);
    line-height: calc((18 / #{$rem}) * 1rem);
  }
}
@media (max-width: 1399.9px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  html {
    font-size: 13px;
  }
}

@import url("https://p.typekit.net/p.css?s=1&k=bpr5zzg&ht=tk&f=39512.39518.39521.39523&a=33101130&app=typekit&e=css");

@font-face {
  font-family: "neue-haas-unica";
  src: url("https://use.typekit.net/af/8a8abc/00000000000000007735bb07/30/l?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/8a8abc/00000000000000007735bb07/30/d?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/8a8abc/00000000000000007735bb07/30/a?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "neue-haas-unica";
  src: url("https://use.typekit.net/af/e07c44/00000000000000007735bb1b/30/l?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e07c44/00000000000000007735bb1b/30/d?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e07c44/00000000000000007735bb1b/30/a?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "neue-haas-unica";
  src: url("https://use.typekit.net/af/579a71/00000000000000007735bb24/30/l?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/579a71/00000000000000007735bb24/30/d?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/579a71/00000000000000007735bb24/30/a?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "neue-haas-unica";
  src: url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/l?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/d?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/a?primer=6fbc0083ad108a48baf26dfe8ca871921e0e5010dcd9cac90de00166bdc312aa&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
