.news {
  &__pagination {
    a:first-of-type:hover {
      transform: translateX(-2px);
    }
    a:last-of-type:hover {
      transform: translateX(2px);
    }
    * {
      width: auto;
    }
    input {
      width: 72px;
      height: 40px;
      border: 1px solid #9a9a9a !important;
      font-size: 17px;
      color: #9a9a9a;
      text-align: center;
    }
  }
}
.blog__card {
  margin-bottom: 50px;
  .text-decoration-none {
    max-height: calc((54 / #{$rem}) * 1rem);
    overflow: hidden;
  }
  &--big {
    .text-decoration-none {
      max-height: initial;
      overflow: visible;
    }
  }
}
.parallax-link {
  a {
    > div:last-of-type {
      width: calc((142 / #{$rem}) * 1rem);
      top: 0px;
    }
  }
}
a {
  .title {
    height: calc((54 / #{$rem}) * 1rem);
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    & > div {
      transform: translateX(20px);
      width: calc(100% - 20px);
      overflow: hidden;
    }
    span {
      position: relative;
      left: 16px;
      transition: 0.4s;
      transition-delay: 0.2s;
      &::before {
        content: "";
        background-image: url("../img/szczalka-orange.svg");
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: calc((-15.62 / #{$rem}) * 1rem);
        background-position: center;
        top: 50%;
        transform: translateY(-50%);
        height: calc((19.4 / 18) * 1rem);
        width: calc((8.62 / #{$rem}) * 1rem);
      }
      &::after {
        content: "";
        position: absolute;
        left: calc((-62.62 / #{$rem}) * 1rem);
        height: calc((2 / 18) * 1rem);
        width: calc((40 / #{$rem}) * 1rem);
        top: 50%;
        background-color: $primary;
        transform: translateY(-50%);
        transition: 0.4s;
        border-radius: 0px 1px 1px 0px;
      }
    }
    &--no-after {
      span {
        left: 0px;
      }
      span {
        left: 0px;
        &::after,
        &::before {
          display: none;
        }
      }
    }
    &--up {
      bottom: initial;
      top: 0px;
    }
  }
  &:hover {
    .title {
      span {
        left: 49px;
      }
      span {
        &::after {
          left: calc((-48 / #{$rem}) * 1rem);
        }
      }
      &--no-after {
        span {
          left: 0px;
        }
      }
    }
  }
}
.gain {
  &__images {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div {
      > div {
        > div {
          margin-bottom: 10px;
        }
      }
      > div:nth-of-type(1) {
        .zoom {
          position: relative;
          top: 7px;
        }
        img {
          max-width: 147px;
        }
      }
      > div:nth-of-type(2) {
        .zoom {
          position: relative;
          bottom: 20px;
        }
        img {
          max-width: 124px;
        }
      }
      > div:nth-of-type(3) {
        img {
          max-width: 106px;
        }
      }
      > div:nth-of-type(4) {
        .zoom {
          position: relative;
          top: 20px;
        }
        img {
          max-width: 110px;
        }
      }
    }
    .zoom {
      width: 80%;
    }
    img {
      max-height: 134px;
      object-fit: contain;
      object-position: center;
    }
  }
}
.faq {
  .news__pagination {
    & > * {
      padding-right: 0px !important;
    }
  }
}
.downloads {
  .accordions-toggler {
    position: absolute;
    z-index: 12;
    right: 0px;
    top: calc((29 / #{$rem}) * 1rem);
  }
  .accordion-header {
    margin-bottom: calc((36 / #{$rem}) * 1rem);
  }
  .accordion-button::after {
    filter: brightness(0);
    position: relative;
    top: 0.33rem;
  }
}
.cards {
  .accordion-item {
    border: 0px;
    border-bottom: 2px solid $gray;
    margin-bottom: calc((82 / #{$rem}) * 1rem);
    .collapsing {
      transition: height 0.7s;
      position: relative;
    }
    button {
      padding-top: 0px;
    }
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
  .accordion-header {
    .zoom {
      width: calc((220 / #{$rem}) * 1rem);
      height: calc((134 / #{$rem}) * 1rem);
    }
  }
  .accordion-toggler {
    background-color: white;
    font-weight: 500;
    color: $primary;
    z-index: 12;
    transition: 0.3s;
    position: absolute;
    right: 0px;
    bottom: 0px;
    border: 2px solid $gray;
    border-bottom: 0px;
    padding: calc((9 / #{$rem}) * 1rem) calc((51 / #{$rem}) * 1rem)
      calc((8 / #{$rem}) * 1rem) calc((23 / #{$rem}) * 1rem);
    line-height: calc((17 / #{$rem}) * 1rem);
  }
  .accordion-button::after {
    position: absolute;
    right: calc((22 / #{$rem}) * 1rem);
    bottom: calc((0 / #{$rem}) * 1rem);
    height: calc((37 / #{$rem}) * 1rem);
    width: calc((18 / #{$rem}) * 1rem);
    z-index: 100;
    background-size: contain;
    background-position: center;
    bottom: calc((-3 / #{$rem}) * 1rem);
  }
  .accordion-body {
    h3 {
      border-bottom: 2px solid $gray;
      line-height: calc((66 / #{$rem}) * 1rem);
      padding-bottom: calc((9.8 / #{$rem}) * 1rem);
      font-weight: 500;
    }
  }
}
.accordion-collapse {
  transition: height 0.35s ease;
}
.cards {
  .accordion-button.collapsed {
    .accordion-toggler {
      color: $black;
    }
  }
}
.infoHeader{
  margin-top: 30px;
  margin-bottom: 25px;
  font-size: 24px;
}
@media (max-width: 1439px) {
}
@media (max-width: 991.9px) {
  .infoHeader{
    font-size:18px;
  }
}

@media (max-width: 767px) {
  .cards {
    .search-input {
      max-width: calc((310 / 18) * 1rem) !important;
      width: calc((310 / 18) * 1rem) !important;
      &.mobile-w-100{
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }
}

@media (min-width: 768px) {
  .gain {
    &__item {
      height: 220px;
    }
  }
}

@media (max-width: 575.9px) {
  .downloads .accordions-toggler {
    top: calc((-37 / 16) * 1rem);
  }
}
