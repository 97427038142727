.section__bg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -2;
  &--gray {
    background-color: #f2f2f2;
  }
  &--black {
    background-color: $black;
  }
  &--primary {
    background-color: $primary;
  }
}
.transition-1 {
  transition: 1s;
}
.mh-auto {
  min-height: auto !important;
}
.mw-auto {
  min-width: auto !important;
}
.w-456 {
  max-width: calc((456 / #{$rem}) * 1rem);
  width: calc((456 / #{$rem}) * 1rem);
}
.w-310 {
  max-width: calc((310 / #{$rem}) * 1rem) !important;
  width: calc((310 / #{$rem}) * 1rem) !important;
}
.w-220 {
  width: calc((220 / #{$rem}) * 1rem);
}
.w-65 {
  width: calc((65 / #{$rem}) * 1rem) !important;
}
.h-60 {
  height: calc((60 / #{$rem}) * 1rem) !important;
}
.h-0 {
  height: 0;
}
.h-43 {
  height: calc((43 / #{$rem}) * 1rem);
}
.h-40 {
  height: calc((40 / #{$rem}) * 1rem);
}
.check-list {
  $padding-left: 22px;
  padding-left: $padding-left;
  list-style: none;
  li {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      background-image: url("../img/checklist.svg");
      background-size: contain;
      background-position: center;
      left: -$padding-left;
      top: 1em;
      width: 1em;
      height: 1em;
      transform: translateY(-75%);
    }
  }
}
.p-small-box {
  padding: calc((4 / #{$rem}) * 1rem) calc((8 / #{$rem}) * 1rem)
    calc((6 / #{$rem}) * 1rem);
}
.p-small-box-border {
  padding: calc((3.2 / #{$rem}) * 1rem) calc((7.2 / #{$rem}) * 1rem)
    calc((5.2 / #{$rem}) * 1rem);
  border: 1px solid $black;
}
.inline-hover {
  * {
    display: inline;
  }
}
.inline-padding {
  > * {
    padding: 10px 16px 12px;
  }
}
.bg-primary-hover {
  transition: 0.3s;
  a:hover {
    background-color: $primary;
    color: $black;
  }
}
.no-color-hover {
  &:hover * {
    color: initial;
  }
}
.color-black-important {
  color: $black !important;
}
.color-hover {
  transition: 0.3;
  &:hover {
    color: $primary;
  }
  &:hover * {
    color: $primary;
  }
}
.bg-black {
  background-color: $black;
}
.bg-gray {
  background-color: #f2f2f2;
  border-radius:8px;
}
.text-bg {
  background-color: $secondary;
  padding: 1px 6px 2px;
}
.standard-size {
  width: calc((176 / #{$rem}) * 1rem);
  height: calc((176 / #{$rem}) * 1rem);
}
.big-click {
  &::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    transform: translate(-25%, -25%);
  }
}
.big-click-relative {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    transform: translate(-25%, -25%);
  }
}
.width-click {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100%;
    left: 0px;
    top: 0px;
  }
}
.max-standard-size {
  width: 100%;
  height: 100%;
  max-width: calc((176 / #{$rem}) * 1rem);
  max-height: calc((176 / #{$rem}) * 1rem);
}
.padding-thing {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  & > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.user-select {
  -webkit-touch-callout: none; /* Safari Touch */
  -webkit-user-select: none; /* Webkit */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Edge*/
  user-select: none; /* Future-proof*/
  * {
    -webkit-touch-callout: none; /* Safari Touch */
    -webkit-user-select: none; /* Webkit */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Edge*/
    user-select: none; /* Future-proof*/
  }
}
.list-none {
  * {
    list-style: none;
  }
}
.list-primary {
  * {
    list-style: none;
  }
  li::before {
    content: "";
    background-color: $primary;
    border-radius: 50%;
    display: inline-block;
    width: calc((12 / #{$rem}) * 1rem);
    height: calc((12 / #{$rem}) * 1rem);
    margin-left: calc((-12 / #{$rem}) * 1rem);
    transform: translateX(-10px);
  }
}
.text-decoration-none {
  * {
    text-decoration: none;
  }
}
.g-16 {
  --bs-gutter-x: 0.88rem;
  --bs-gutter-y: 0.88rem;
}
.gy-46 {
  --bs-gutter-y: calc((46 / #{$rem}) * 1rem);
}
.gy-30 {
  --bs-gutter-y: calc((30 / #{$rem}) * 1rem);
}
.gy-16 {
  --bs-gutter-y: calc((16 / #{$rem}) * 1rem);
}
.gx-30 {
  --bs-gutter-x: calc((30 / #{$rem}) * 1rem);
}
.gx-16 {
  --bs-gutter-x: calc((16 / #{$rem}) * 1rem);
}
.my-30 {
  margin-top: calc((30 / #{$rem}) * 1rem);
  margin-bottom: calc((30 / #{$rem}) * 1rem);
}
.color-primary {
  color: $primary;
}
.color-black {
  color: black;
}
.color-gray {
  color: #808080;
}
.vertically {
  writing-mode: tb-rl;
  transform: rotate(180deg);
}
.rotate-90 {
  transform: rotate(90deg);
}
.fw-semibold {
  font-weight: 600;
}
.fw-regular {
  font-weight: 500;
}
.lh-1 {
  line-height: 1;
}
.lh-28 {
  line-height: calc((28 / #{$rem}) * 1rem);
}
.lh-24 {
  line-height: 1.33rem;
}
.lh-21 {
  line-height: 1.166rem;
}
.lh-18 {
  line-height: calc((21 / #{$rem}) * 1rem);
}
.ls-24 {
  letter-spacing: 0.013rem;
}
.ls-22 {
  letter-spacing: calc((0.22 / #{$rem}) * 1rem);
}

.ls-14 {
  letter-spacing: 0.0077rem;
}
.ls-9 {
  letter-spacing: 0.005rem;
}
.ls-06 {
  letter-spacing: 0.6px;
}
.padding-thing {
  position: relative;
  width: 100%;
  padding-bottom: 56.25% !important;
  &.padding-thing--low {
    padding-bottom: 30% !important;
  }
  &.padding-thing--square {
    padding-bottom: 100% !important;
  }
  &.padding-thing--medium-square {
    padding-bottom: 89% !important;
  }
  &.padding-thing--71 {
    padding-bottom: 71.25% !important;
  }
  & > * {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.zoom {
  overflow: hidden;
  img {
    transition: 1.5s;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    &.user-select{
      object-fit:contain;
    }
  }
  &:hover img {
    transform: scale(1.04);
  }
  &--less {
    &:hover img {
      transform: scale(1.02);
    }
  }
  &--not-cut {
    img {
      transform: scale(0.96);
    }
    &:hover img {
      transform: scale(1);
    }
  }
}
.fb-initial {
  flex-basis: initial !important;
}
@media (min-width: 1200px) {
  .w-xl-75 {
    width: 75%;
  }
}
@media (min-width: 767px) {
  .w-md-100 {
    width: 100% !important;
  }
}
@media (max-width: 1439.9px) {
  .w-456 {
    max-width: calc((350 / #{$rem}) * 1rem);
    width: calc((350 / #{$rem}) * 1rem);
  }
}
@media (max-width: 991.9px) {
  .padding-thing {
    &.padding-thing--low {
      padding-bottom: 56.25% !important;
    }
  }
}
@media (max-width: 767px) {
  .mobile-w-100{
    max-width: 100%!important;
    width: 100% !important;
  }
}
@media (max-width: 575.9px) {
  .padding-thing {
    &.padding-thing--low {
      padding-bottom: 100.25% !important;
    }
  }
  .w-100-sm {
    width: 100% !important;
  }
}
